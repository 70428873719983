import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import KfgThumbnail from "../components/kfgThumbnail"

const Index = ({ data }) => {
  const icons = data.icons
  const illusts = data.illusts
  const kfg = data.kfg

  useEffect(() => {
    try {
      window._mNHandle.queue.push(function () {
        window._mNDetails.loadTag("334225507", "728x90", "334225507")
      })
    } catch (error) {}
  }, [])

  const intl = useIntl()
  return (
    <Layout>
      <SEO />
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <div className="md:hidden mb-3 p-3 text-sm bg-white rounded border border-gray-400">
          <p className="font-bold text-lg">
            {intl.formatMessage({ id: "side.welcome" })}
          </p>
          <div className="pt-2">{intl.formatMessage({ id: "side.about" })}</div>
        </div>
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "category.icons" })}
        </h3>
        <div className="images grid grid-cols-4 gap-2 sm:grid-cols-5 md:grid-cols-7 md:gap-3 mb-8">
          {icons.edges.map(({ node }, index) => (
            <div
              key={index}
              className="image bg-white rounded border border-gray-400 hover:shadow"
            >
              <Link to={"/icons/download?q=" + node.name}>
                <img
                  src={"/images/" + node.cat + "/" + node.name + ".svg"}
                  alt={node.tag}
                  className="p-2"
                  loading="lazy"
                ></img>
              </Link>
            </div>
          ))}
          <div className="image bg-white rounded border border-gray-400 hover:shadow">
            <Link to="/icons/">
              <div className="h-full w-full py-6 text-center font-semibold flex flex-col justify-center">
                More...
              </div>
            </Link>
          </div>
        </div>
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "kfg.title" })}
        </h3>
        <div className="main-images mb-8">
          <div className="images grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-3">
            <KfgThumbnail kfg={data.kfg} />
            <div className="image bg-white rounded border border-gray-400 overflow-hidden hover:shadow">
              <Link to="/kung-fu-geek/">
                <div className="h-full w-full py-6 text-center font-semibold flex flex-col justify-center">
                  More...
                </div>
              </Link>
            </div>
          </div>
        </div>
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "category.illusts" })}
        </h3>
        <div className="main-images mb-8">
          <div className="images grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-3">
            {illusts.edges.map(({ node }, index) => (
              <div
                key={index}
                className="image bg-white rounded border border-gray-400 overflow-hidden hover:shadow"
              >
                <Link to={"/illustrations/download?q=" + node.name}>
                  <img
                    src={"/images/" + node.cat + "/" + node.name + ".svg"}
                    alt={node.tag}
                    loading="lazy"
                  ></img>
                </Link>
              </div>
            ))}
            <div className="image bg-white rounded border border-gray-400 overflow-hidden hover:shadow">
              <Link to="/illustrations/">
                <div className="h-full w-full py-6 text-center font-semibold flex flex-col justify-center">
                  More...
                </div>
              </Link>
            </div>
          </div>
        </div>
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "category.tools" })}
        </h3>
        <div className="mx-auto mb-6 w-full">
          <Link to="/tools/featured-image-generator">
            <div className="bg-white relative rounded block md:flex border border-gray-400 min-h-12rem hover:shadow">
              <div className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t md:rounded-t-none md:rounded-l min-h-12rem">
                <img
                  className="absolute inset-0 w-full h-full object-cover object-center"
                  src="/images/tools/eye-catch-maker.svg"
                  alt=""
                ></img>
              </div>
              <div className="w-full md:w-3/5 h-full rounded">
                <div className="p-3 md:p-6">
                  <p className="text-lg md:text-xl font-bold mb-3">
                    {intl.formatMessage({ id: "tools.featuredImageGen" })}
                  </p>
                  <p className="text-sm md:text-base">
                    {intl.formatMessage({ id: "tools.featuredImageGenDesc" })}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </section>
    </Layout>
  )
}
export default Index

export const query = graphql`
  query {
    icons: allSvgItemsJson(
      sort: { fields: id, order: DESC }
      limit: 19
      filter: { cat: { eq: "icons" } }
    ) {
      edges {
        node {
          id
          cat
          name
          tag
        }
      }
    }
    illusts: allSvgItemsJson(
      sort: { fields: id, order: DESC }
      filter: { cat: { eq: "illustrations" } }
      limit: 12
    ) {
      edges {
        node {
          id
          cat
          name
          tag
        }
      }
    }
    kfg: allSvgItemsJson(
      sort: { fields: id, order: DESC }
      filter: { cat: { eq: "kung-fu-geek" } }
      limit: 7
    ) {
      edges {
        node {
          id
          cat
          name
          tag
        }
      }
    }
  }
`
